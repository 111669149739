import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Router } from 'react-router';

import browserHistory from './history';

export const RouterContext = createContext({});

const AppRouter = ({ children }) => (
  <Router history={browserHistory}>
    <Route>{routeProps => <RouterContext.Provider value={routeProps}>{children}</RouterContext.Provider>}</Route>
  </Router>
);

AppRouter.propTypes = {
  children: PropTypes.node,
};

export default AppRouter;
