import styled from 'styled-components';

import { media } from '../../../../theme/media';
import { colors, metrics } from '../../../../theme/styled';
import arrow from '../../../../images/icons/arrow-black.svg';
import { Paragraph } from '../../../../theme/typography';

export const Container = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: ${colors.primary};
  padding: 0;
  text-decoration: none;
  text-align: left;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    width: 24px;
    height: 15px;
    background-image: url(${arrow});
    background-size: cover;
    transform: rotate(-90deg);
    margin-left: -5px;
    margin-right: 16px;
  }

  ${Paragraph} > & {
    margin-top: ${metrics.buttonBottomMargin.mobile}px;

    ${media.tablet`
      margin-top: ${metrics.buttonBottomMargin.tablet}px;
    `};

    ${media.desktop`
      margin-top: ${metrics.buttonBottomMargin.desktop}px;
    `};

    ${media.desktopWide`
      margin-top: ${metrics.buttonBottomMargin.desktopWide}px;
    `};

    ${media.desktopFull`
      margin-top: ${metrics.buttonBottomMargin.desktopFull}px;
    `};
  }
`;
