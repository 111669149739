import styled, { css } from 'styled-components';
import { pipe, propOr, dec } from 'ramda';

import { fonts, fontSizes, fontWeights, colors, metrics } from './styled';
import { media } from './media';

export const linkUnderlineStyle = css`
  overflow: hidden;

  &:after {
    background-color: currentColor;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
  }
`;

const inlineHyperlinkStyles = css`
  a {
    font-weight: inherit;
    display: inline-block;
    vertical-align: top;
    ${linkUnderlineStyle};
  }
`;

const headerStyles = css`
  font-family: ${fonts.neueHaas};
  font-weight: ${fontWeights.bold};
  line-height: 1.53;
  margin: 0 0 1rem;
  ${inlineHyperlinkStyles}
`;

export const H1 = styled.h1`
  ${headerStyles};
  font-size: ${fontSizes.h1.mobile};

  ${media.tablet`
    font-size: ${fontSizes.h1.tablet};
  `}

  ${media.desktop`
    font-size: ${fontSizes.h1.desktop};
  `}
`;

export const H2 = styled.h2`
  ${headerStyles};
  font-size: ${fontSizes.h2.mobile};

  ${media.tablet`
    font-size: ${fontSizes.h2.tablet};
  `}

  ${media.desktop`
    font-size: ${fontSizes.h2.desktop};
  `}
`;

export const H3 = styled.h3`
  ${headerStyles};
  margin-top: 0;
  margin-bottom: 27px;
  font-size: ${fontSizes.h3.mobile};

  ${media.desktop`
    margin-bottom: 30px;
  `}

  ${media.desktopWide`
    margin-bottom: 35px;
    font-size: ${fontSizes.h3.desktopWide};
  `}

  ${media.desktopFull`
    margin-bottom: 45px;
  `}
`;

export const H4 = styled.h4`
  ${headerStyles};
  font-size: ${fontSizes.h4.mobile};
`;

export const H5 = styled.h5`
  ${headerStyles};
  font-size: ${fontSizes.h5.mobile};
`;

export const H6 = styled.h6`
  ${headerStyles};
  font-size: ${fontSizes.h6.mobile};
`;

export const Paragraph = styled.p`
  font-family: ${fonts.neueHaas};
  font-weight: ${fontWeights.normal};
  color: ${colors.primary};
  margin: 0 0 20px;
  text-align: left;
  line-height: 1.92;
  font-size: ${fontSizes.paragraph.mobile};

  ${media.desktop`
    line-height: 1.6;
  `};

  ${media.desktopWide`
    font-size: ${fontSizes.paragraph.desktopWide};
    margin-bottom: 36px;
  `};

  ${media.desktopFull`
    line-height: 1.735;
  `};

  main & {
    ${inlineHyperlinkStyles};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Strong = styled.strong`
  font-family: ${fonts.neueHaas};
  font-weight: ${fontWeights.bold};
`;

export const Hr = styled.hr`
  width: 100%;
  border-color: ${colors.accent};
  border-width: thin;
  border-bottom: none;
  margin: 20px 0;

  ${media.tablet`
    margin: 30px 0;
  `}

  ${media.desktopWide`
    margin: 60px 0;
  `}
`;

export const Blockquote = styled.blockquote`
  margin: 0;
`;

const listStyles = css`
  margin: 0;
  padding-left: 0;
  list-style: none;
  text-align: left;
`;

export const Ul = styled.ul`
  ${listStyles};
`;

export const UnorderedListItem = styled.li`
  font-size: ${fontSizes.unorderedListItem.mobile};
  line-height: 2;
  display: flex;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: 2px;
    height: 2px;
    margin-top: 9px;
    flex: none;
    border-radius: 50%;
    background-color: ${colors.primary};
  }

  ${media.desktop`
    font-size: ${fontSizes.unorderedListItem.desktop};
    line-height: 2.29;

    &::before {
      margin-top: 15px;
    }
  `};

  ${media.desktopFull`
    font-size: ${fontSizes.unorderedListItem.desktopFull};
    line-height: 2.47;

    &::before {
      margin-top: 18px;
    }
  `};
`;

const getCounterStart = pipe(
  propOr(1, 'startFrom'),
  dec
);

export const Ol = styled.ol`
  ${listStyles};
  counter-reset: counter ${getCounterStart};
  box-sizing: border-box;
`;

export const OrderedListItem = styled.li`
  counter-increment: counter;
  font-size: ${fontSizes.paragraph.mobile};
  line-height: 1.73;
  position: relative;
  font-weight: ${fontWeights.normal};
  display: flex;

  ${media.desktopWide`
    font-size: ${fontSizes.paragraph.desktopWide};
  `};

  &::before {
    content: counter(counter, decimal-leading-zero);
    font-weight: ${fontWeights.bold};
    margin-right: 25px;
  }
`;

export const paragraphsDistanceStyle = css`
  margin-top: ${metrics.buttonBottomMargin.mobile}px;

  ${media.tablet`
    margin-top: ${metrics.buttonBottomMargin.tablet}px;
  `};

  ${media.desktop`
    margin-top: ${metrics.buttonBottomMargin.desktop}px;
  `};

  ${media.desktopWide`
    margin-top: ${metrics.buttonBottomMargin.desktopWide}px;
  `};

  ${media.desktopFull`
    margin-top: ${metrics.buttonBottomMargin.desktopFull}px;
  `};
`;

export const paragraphsWidthStyle = css`
  width: ${metrics.sectionParagraphWidth.mobile}px;

  ${media.tablet`
    width: ${metrics.sectionParagraphWidth.tablet}px;
  `};

  ${media.desktop`
    width: ${metrics.sectionParagraphWidth.desktop}px;
  `};

  ${media.desktopWide`
    width: ${metrics.sectionParagraphWidth.desktopWide}px;
  `};

  ${media.desktopFull`
    width: ${metrics.sectionParagraphWidth.desktopFull}px;
  `};
`;

export const sectionWidthStyles = css`
  width: calc(100% - 48px);

  ${media.tablet`
    width: calc(100% - 50px);
  `};

  ${media.desktop`
    width: calc(100% - 90px);
  `};
`;

export const sectionMarginStyles = css`
  margin: ${metrics.sectionTopMargin.mobile}px auto 0;

  ${media.tablet`
    margin: ${metrics.sectionTopMargin.tablet}px auto  0;
  `};

  ${media.desktop`
    margin: ${metrics.sectionTopMargin.desktop}px auto 0;
  `};
`;

export const getHeightStyles = ({ dynamic = true } = {}) => {
  const styleProperty = dynamic ? 'min-height' : 'height';

  return css`
    ${styleProperty}: calc(100vh - ${metrics.mainMenuHeight.mobile}px);

    ${media.tabletPortrait`
      ${styleProperty}: calc((100vh - ${metrics.mainMenuHeight.mobile}px) / 2);
    `}

    ${media.desktop`
      ${styleProperty}: calc(100vh - ${metrics.mainMenuHeight.desktop}px);
    `};

    ${media.desktopWide`
      ${styleProperty}: calc(100vh - ${metrics.mainMenuHeight.desktopWide}px);
    `};

    ${media.desktopFull`
      ${styleProperty}: calc(100vh - ${metrics.mainMenuHeight.desktopFull}px);
    `};
  `;
};

export const Section = styled.section`
  ${getHeightStyles()};
  ${media.mobile`
    & > * {
      margin-top: 24px;
    }
  `}

  ${media.tablet`
    & > * {
      margin: 0;
    }

    margin: 25px auto 0;
  `}

  ${media.desktop`
    margin: 45px auto 0;
  `}
`;

export const linkStyles = css`
  color: ${colors.primary};
  font-family: ${fonts.neueHaas};
  text-decoration: none;
  font-weight: ${fontWeights.normal};
`;

export const Link = styled.a`
  ${linkStyles};
`;

export const buttonLinkStyles = css`
  ${linkStyles};
  display: table;
  text-align: left;
  ${linkUnderlineStyle};
`;

export const ButtonLink = styled.a`
  ${buttonLinkStyles}
`;
