import styled from 'styled-components';
import { colors, cursorUrls, fontSizes } from '../../../../theme/styled';
import { media } from '../../../../theme/media';
import { Link as StyledLink } from '../../../../theme/typography';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const MarkerImg = styled.img`
  cursor: url(${cursorUrls.pointer}) 12 12, auto !important;
  height: 38px;
`;

export const Link = styled.a`
  pointer-events: auto;
  display: inline-block;
`;

export const GoogleMapsLink = styled(StyledLink)`
  font-size: ${fontSizes.paragraph.mobile};
  line-height: 1;
  background-color: ${colors.white};
  padding: 10px 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: auto;

  ${media.desktopWide`
    font-size: ${fontSizes.paragraph.desktopWide};
    padding: 15px 20px;
    top: 15px;
    right: 15px;
  `};
`;
