import styled, { css } from 'styled-components';
import { Text as TextComponent } from '../text';
import { media } from '../../../theme/media';
import { colors, metrics } from '../../../theme/styled';
import { H3, Paragraph, getHeightStyles, sectionWidthStyles, sectionMarginStyles } from '../../../theme/typography';

export const Container = styled.footer`
  display: flex;
  height: auto;
  flex-direction: column;
  margin: ${metrics.sectionTopMargin.mobile}px auto 0;
  ${sectionWidthStyles};

  ${media.tablet`
    margin-top: ${metrics.sectionTopMargin.tablet}px;
  `};

  ${media.desktop`
    margin-top: ${metrics.sectionTopMargin.desktop}px;
  `};
`;

export const Content = styled.div`
  background-color: ${colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${getHeightStyles()};
`;

export const ContentContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 105px;
  padding-bottom: 105px;
  width: 230px;

  ${media.tablet`
    width: 648px;
    padding-top: 102px;
    padding-bottom: 142px;
  `}

  ${media.desktop`
    width: 720px;
    padding-top: 105px;
    padding-bottom: 105px;
  `}
`;

const StyledH3 = styled(H3);

const footerTextDesktopStyles = css`
  line-height: 1.92;

  ${media.desktop`
    line-height: 1.67;
  `}

  ${media.desktopWide`
    line-height: 1.6;
  `}

  ${media.desktopFull`
    line-height: 1.73;
  `}
`;

export const ContentBox = styled.div`
  margin-bottom: 45px;
  width: 270px;

  ${media.tablet`
    width: 130px;
    margin-right: 128px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  `}

  ${media.desktop`
    width: auto;
    margin-right: 90px;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled(TextComponent)`
  margin-bottom: 45px;

  ${StyledH3} {
    font-size: inherit;
  }

  ${Paragraph} {
    ${footerTextDesktopStyles};
  }
`;

export const Description = styled(Text)`
  margin-bottom: 45px;

  ${media.tablet`
    margin-bottom: 55px;
    width: 100%;
  `}

  ${media.desktopWide`
    margin-bottom: 95px;
  `}

  ${Paragraph} {
    width: 270px;

    ${media.tablet`
      width: 260px;
    `}

    ${media.desktop`
      width: 480px;
    `}

    ${footerTextDesktopStyles};
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  ${sectionMarginStyles};
  ${getHeightStyles({ dynamic: false })};
`;
