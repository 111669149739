import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontSizes } from '../../../../theme/styled';
import { linkStyles, linkUnderlineStyle } from '../../../../theme/typography';
import { media } from '../../../../theme/media';

const commonStyles = css`
  text-transform: uppercase;
  overflow: hidden;
  ${linkStyles};
  font-size: ${fontSizes.menu.mobile};

  ${media.tablet`
    margin-top: 2px;
    padding: 20px 0;
    font-size: ${fontSizes.menu.tablet};
  `};

  ${media.desktop`
    font-size: ${fontSizes.menu.desktop};
  `};

  ${media.desktopFull`
    padding: 25px 0;
    font-size: ${fontSizes.menu.desktopFull};
  `};

  &.active {
    ${linkUnderlineStyle};
  }
`;

export const MenuLink = styled(NavLink)`
  ${commonStyles}
`;

export const MenuAnchorLink = styled.a`
  ${commonStyles}
`;
