import React from 'react';
import PropTypes from 'prop-types';

import { Container, Tag } from './tags.styles';

const renderItems = values => values.map((value, index) => <Tag key={`${value}-${index}`}>{value}</Tag>);

export const Tags = ({ values }) => {
  return <Container>{renderItems(values)}</Container>;
};

Tags.propTypes = {
  values: PropTypes.array.isRequired,
};
