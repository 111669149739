import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { compose } from 'ramda';

import * as GTM from '../shared/services/gtm';
import { StartupActions } from '../modules/startup';
import { translationMessages, DEFAULT_LOCALE } from '../i18n';
import { GlobalStyle } from '../theme/global';
import { selectSettings, selectGTM } from '../modules/contentRegistry';
import { renderWhenTrue } from '../shared/utils/rendering';
import { MainMenu, Footer, Loader, CookiesBar } from '../shared/components';
import messages from './app.messages';

const renderFooterAndCookies = renderWhenTrue(() => (
  <>
    <Footer />
    <CookiesBar />
  </>
));

export const AppComponent = ({ children, history }) => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const gtm = useSelector(selectGTM);

  const handleHistoryChange = ({ pathname }) => GTM.logRouteChange(pathname);

  const initGTM = () => {
    if (gtm) {
      GTM.init();
    }
  };

  useEffect(() => {
    dispatch(StartupActions.startup());
  }, []);

  useEffect(() => {
    const historyListener = history.listen(handleHistoryChange);
    initGTM();

    return () => {
      historyListener();
    };
  }, [gtm]);

  return (
    <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]} textComponent={Fragment}>
      <Fragment>
        <FormattedMessage {...messages.pageTitle}>
          {pageTitle => (
            <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle}>
              <meta property="og:title" content={pageTitle} />
            </Helmet>
          )}
        </FormattedMessage>

        <GlobalStyle />
        <MainMenu />
        <Loader />
        <main>{React.Children.only(children)}</main>
        {renderFooterAndCookies(!!settings)}
      </Fragment>
    </IntlProvider>
  );
};

AppComponent.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
};

export const App = compose(withRouter)(AppComponent);
