import { always, ifElse, isNil, T, lte, cond } from 'ramda';
import UAParser from 'ua-parser-js';
import { metrics } from '../../theme/styled';
import { sizes, getWindowWidth } from '../../theme/media';

const getScrollOffset = () =>
  cond([
    [lte(sizes.desktopFull), always(metrics.mainMenuHeight.desktopFull - metrics.sectionTopMargin.desktop)],
    [lte(sizes.desktopWide), always(metrics.mainMenuHeight.desktopWide - metrics.sectionTopMargin.desktop)],
    [lte(sizes.desktop), always(metrics.mainMenuHeight.desktop - metrics.sectionTopMargin.desktop)],
    [lte(sizes.tablet), always(metrics.mainMenuHeight.mobile - metrics.sectionTopMargin.tablet)],
    [T, always(metrics.mainMenuHeight.mobile - metrics.sectionTopMargin.mobile)],
  ])(getWindowWidth());

export const whenElementExist = (id, callback) =>
  ifElse(isNil, always(null), ref => callback(ref))(document.getElementById(id));

export const scrollToRef = ref => {
  const extraOffset = getScrollOffset() + parseInt(getComputedStyle(ref).marginTop, 10);
  const parser = new UAParser();

  if (parser.getBrowser().name === 'IE') {
    window.scrollTo(0, ref.offsetTop - extraOffset);
  } else {
    window.scrollTo({
      top: ref.offsetTop - extraOffset,
      behavior: 'smooth',
    });
  }
};
