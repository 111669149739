import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { buttonLinkStyles, paragraphsDistanceStyle } from '../../../../theme/typography';

export const Link = styled(RouterLink)`
  ${buttonLinkStyles};
  ${paragraphsDistanceStyle};
  font-size: inherit;
  color: inherit;
`;
