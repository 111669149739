import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { when, equals, always } from 'ramda';

import { selectAssets, selectAssetsLoaded } from '../../../modules/contentRegistry';
import { VideoContainer } from './video.styles';
import { getAssetPath, getContentType } from '../../utils/assets';

export const Video = memo(({ className, video, type }) => {
  const assets = useSelector(selectAssets);
  const sourcePath = getAssetPath(assets, video);
  const videoType = getContentType(assets, video);
  const preloaderVisible = useSelector(selectAssetsLoaded);
  const videoProps = when(
    equals(true),
    always({
      autoPlay: true,
      playsInline: true,
    })
  )(preloaderVisible);
  const refElement = useRef(null);

  useEffect(() => {
    objectFitPolyfill(refElement.current);
  }, []);

  return (
    <VideoContainer
      key={preloaderVisible}
      className={className}
      {...videoProps}
      loop
      muted
      preload="auto"
      data-object-fit="cover"
      ref={refElement}
    >
      <source src={sourcePath} type={type || videoType} />
    </VideoContainer>
  );
});

Video.displayName = 'Video';

Video.propTypes = {
  className: PropTypes.string,
  video: PropTypes.string.isRequired,
  type: PropTypes.string,
};
