import TagManager from 'react-gtm-module';

const EVENTS = {
  routeChange: 'virtualPageView',
};

export const init = () =>
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    preview: process.env.REACT_APP_GTM_PREVIEW,
    auth: process.env.REACT_APP_GTM_AUTH,
  });

export const logEvent = (event, properties) => TagManager.dataLayer({ dataLayer: { event, ...properties } });

export const push = (dataLayer = {}) => TagManager.dataLayer({ dataLayer });

export const logRouteChange = virtualPagePath => logEvent(EVENTS.routeChange, { virtualPagePath });
