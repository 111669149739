import styled, { keyframes } from 'styled-components';
import posed from 'react-pose';

import { ReactComponent as LogoSVG } from '../../../images/icons/hyper-logo.svg';
import { colors } from '../../../theme/styled';

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AnimatedContainer = posed(Container)({
  exit: {
    opacity: 0,
    delay: 500,
    transition: { duration: 450 },
  },
});

const scaleOut = keyframes`
  0% {
    opacity: 0.01;
    transform: scale(0.96);
  }
  100% {
    transform: scale(1.0);
  }
`;

export const HyperLogo = styled(LogoSVG)`
  fill: ${colors.primary};
  width: 160px;
  height: 83px;
  animation: ${scaleOut} 2s infinite ease-in-out;
  animation-direction: alternate;
`;
