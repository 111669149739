import React from 'react';
import PropTypes from 'prop-types';

import { Link } from './button.styles';
import { ROUTES } from '../../../../routes/routes';

export const Button = ({ values: { url = ROUTES.home, text } }) => {
  return <Link to={url}>{text}</Link>;
};

Button.propTypes = {
  values: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
};
