import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { pick } from 'ramda';
import ReactCompareImage from 'react-compare-image';

import { POSES } from '../../../theme/animated';
import { ContentRegistryPropTypes, selectAssets } from '../../../modules/contentRegistry';
import { getAssetPath, getImageAlt } from '../../utils/assets';
import { useOnScreen } from '../../utils/hooks';
import { Container, Handle } from './imagesComparator.styles';

export const ImagesComparator = ({ firstImage, secondImage }) => {
  const assets = useSelector(selectAssets);
  const { firstImageSrc, secondImageSrc, firstImageAlt, secondImageAlt } = useMemo(
    () => ({
      firstImageSrc: getAssetPath(assets, firstImage),
      secondImageSrc: getAssetPath(assets, secondImage),
      firstImageAlt: getImageAlt(assets, firstImage),
      secondImageAlt: getImageAlt(assets, secondImage),
    }),
    [firstImage, secondImage, assets]
  );

  const containerRef = useRef();
  const isContainerOnScreen = useOnScreen(containerRef, '-20px', true);

  const pose = isContainerOnScreen ? POSES.VISIBLE : POSES.INIT;

  return (
    <Container ref={containerRef}>
      <ReactCompareImage
        leftImage={firstImageSrc}
        rightImage={secondImageSrc}
        leftImageAlt={firstImageAlt}
        rightImageAlt={secondImageAlt}
        sliderLineWidth={0}
        handle={<Handle pose={pose} />}
      />
    </Container>
  );
};

ImagesComparator.propTypes = {
  ...pick(['firstImage', 'secondImage'], ContentRegistryPropTypes.imagesComparator),
};
