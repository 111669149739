import { identity } from 'ramda';

const parseValues = (value, startPosition, endPosition) =>
  value
    .slice(startPosition, endPosition)
    .replace(/\(|\)/g, '')
    .split(',')
    .map(c => c.trim());

export function tagsShortcode() {
  const transformValues = (value, startPosition, endPosition) => parseValues(value, startPosition, endPosition);

  // eslint-disable-next-line babel/no-invalid-this
  return createShortcode('tags', transformValues, this.Parser.prototype);
}

export function scrollToShortcode() {
  const transformValues = (value, startPosition, endPosition) => {
    const values = parseValues(value, startPosition, endPosition);

    return {
      id: values[0],
      text: values[1],
    };
  };

  // eslint-disable-next-line babel/no-invalid-this
  return createShortcode('scrollTo', transformValues, this.Parser.prototype);
}

export function buttonShortcode() {
  const transformValues = (value, startPosition, endPosition) => {
    const values = parseValues(value, startPosition, endPosition);

    return {
      url: values[0],
      text: values[1],
    };
  };

  // eslint-disable-next-line babel/no-invalid-this
  return createShortcode('button', transformValues, this.Parser.prototype);
}

function createShortcode(name, transformValues = identity, parser) {
  if (!name) {
    return;
  }
  const startBlock = `{{${name}`;
  const endBlock = '}}';
  const tokenizers = parser.inlineTokenizers;
  const methods = parser.inlineMethods;

  tokenizers[name] = shortcodeTokenizer;
  methods.splice(methods.indexOf('html'), 0, name);

  function shortcodeTokenizer(eat, value) {
    if (!value.startsWith(startBlock)) {
      return;
    }
    const endBlockPosition = value.indexOf(endBlock, startBlock.length);
    if (endBlockPosition === -1) {
      return;
    }

    const endPosition = endBlockPosition + endBlock.length;
    const entireShortcode = value.slice(0, endPosition);
    const innerShortcode = transformValues(value, startBlock.length, endBlockPosition);

    // eslint-disable-next-line consistent-return
    return eat(entireShortcode)({
      type: name,
      values: innerShortcode,
    });
  }

  shortcodeTokenizer.locator = (value, fromIndex) => value.indexOf(startBlock, fromIndex);
}
