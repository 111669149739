import { createActions, createReducer } from 'reduxsauce';
import { mapObjIndexed } from 'ramda';
import Immutable from 'seamless-immutable';

export const { Types: ContentRegistryTypes, Creators: ContentRegistryActions } = createActions(
  {
    addEntities: ['newEntities'],
    setLocation: ['location'],
    loadAssets: null,
    loadAssetsSuccess: null,
    loadAssetsError: null,
    setCookies: ['isCookiesAccepted'],
    setCookiesSuccess: ['cookies'],
    setGTM: ['gtm'],
  },
  { prefix: 'CONTENT_REGISTRY/' }
);

export const INITIAL_STATE = new Immutable({
  entities: {
    anchorLinks: {},
    assets: {},
    settings: {},
    pages: {},
    sections: {},
    imagesComparators: {},
    heroes: {},
    testimonialsSections: {},
    testimonials: {},
  },
  assetsLoaded: false,
  location: null,
  cookies: false,
  gtm: false,
});

const loadSuccess = state => ({
  ...state,
  assetsLoaded: true,
});

const loadError = state => ({
  ...state,
  assetsLoaded: true,
});

const setLocation = (state, { location }) => ({
  ...state,
  location,
});

const addEntities = (state, { newEntities }) => ({
  ...state,
  entities: mapObjIndexed((entitiesByType, type) => {
    return {
      ...entitiesByType,
      ...(newEntities[type] || {}),
    };
  })(state.entities),
});

const setCookiesSuccess = (state, { cookies }) => ({
  ...state,
  cookies,
});

const setGTM = (state, { gtm }) => ({
  ...state,
  gtm,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ContentRegistryTypes.ADD_ENTITIES]: addEntities,
  [ContentRegistryTypes.SET_LOCATION]: setLocation,
  [ContentRegistryTypes.LOAD_ASSETS_SUCCESS]: loadSuccess,
  [ContentRegistryTypes.LOAD_ASSETS_ERROR]: loadError,
  [ContentRegistryTypes.SET_COOKIES_SUCCESS]: setCookiesSuccess,
  [ContentRegistryTypes.SET_GTM]: setGTM,
});
