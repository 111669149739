import React from 'react';
import PropTypes from 'prop-types';

import { Ul, Ol, UnorderedListItem, OrderedListItem } from '../../../../theme/typography';

export const List = ({ children, ordered, start }) => {
  if (ordered) {
    return (
      <Ol startFrom={start}>
        {children.map(({ key, props }) => (
          <OrderedListItem key={key}>{props.children}</OrderedListItem>
        ))}
      </Ol>
    );
  }
  return (
    <Ul>
      {children.map(({ key, props }) => (
        <UnorderedListItem key={key}>{props.children}</UnorderedListItem>
      ))}
    </Ul>
  );
};

List.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      props: PropTypes.shape({
        children: PropTypes.node.isRequired,
      }),
    })
  ).isRequired,
  ordered: PropTypes.bool,
  start: PropTypes.number,
};
