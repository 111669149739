import styled from 'styled-components';
import posed from 'react-pose';

import { colors, metrics } from '../../../theme/styled';
import { media, isMobile } from '../../../theme/media';
import { ReactComponent as LogoSVG } from '../../../images/icons/hyper-logo.svg';
import { MenuAnchorLink, MenuLink } from './menuItem/menuItem.styles';

export const Container = styled.header`
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;

  ${media.tablet`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    left: 0;
    background-color: ${colors.white};
    z-index: 10;
    box-sizing: border-box;
    height: ${metrics.mainMenuHeight.mobile}px;
    padding: 0 25px;
  `};

  ${media.desktop`
    height: ${metrics.mainMenuHeight.desktop}px;
    padding: 0 45px;
  `};

  ${media.desktopWide`
    height: ${metrics.mainMenuHeight.desktopWide}px;
  `};

  ${media.desktopFull`
    height: ${metrics.mainMenuHeight.desktopFull}px;
  `};
`;

export const MobileBar = styled.div`
  position: absolute;
  z-index: 11;
  height: ${metrics.mainMenuHeight.mobile}px;
  width: 100%;
  background-color: ${colors.white};
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  backface-visibility: hidden;

  ${media.tablet`
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    display: block;
    background-color: transparent;
  `};
`;

export const Menu = styled.nav`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 26px;
  height: calc(100vh - ${metrics.mainMenuHeight.mobile}px);
  margin-top: ${metrics.mainMenuHeight.mobile}px;
  background-color: ${colors.white};

  ${media.tablet`
    position: relative;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    height: auto;
    padding: 0;
    margin-top: 0;
    transform: none;
    background-color: transparent;
    transition: none;
  `};

  ${MenuLink}, ${MenuAnchorLink} {
    ${media.tablet`
      margin: 2px 20px 0;
    `};

    ${media.desktopFull`
      margin: 0 74px;
    `};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AnimatedLink = posed.div({
  open: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 300,
      },
    },
  },
  closed: {
    opacity: () => (isMobile() ? 0 : 1),
  },
});

export const AnimatedMenu = posed(Menu)({
  open: {
    opacity: 1,
    y: 0,
    delayChildren: 200,
    staggerDirection: 1,
    transition: {
      y: { type: 'tween' },
    },
  },
  closed: {
    opacity: () => (isMobile() ? 0 : 1),
    y: () => (isMobile() ? '-100%' : 0),
    delay: 300,
    staggerDirection: -1,
  },
});

export const HyperLogo = styled(LogoSVG)`
  fill: ${colors.primary};
  width: 67px;
  height: 33px;
  margin-top: 5px;

  ${media.tablet`
    margin-top: 3px;
  `};

  ${media.desktopFull`
    margin-top: 4px;
    width: 97px;
    height: 50px;
  `};
`;
