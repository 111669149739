import { equals, always, ifElse } from 'ramda';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { renderWhenTrue } from '../../utils/rendering';
import { useMobileDetect, useOnScreen } from '../../utils/hooks';
import { POSES } from '../../../theme/animated';
import { selectSettings } from '../../../modules/contentRegistry';
import { Map } from './map';
import { Container, ContentBox, Content, ContentContainer, Description, MapWrapper, Text } from './footer.styles';

export const Footer = () => {
  const settings = useSelector(selectSettings);
  const contentContainerRef = useRef();
  const isMobile = useMobileDetect();
  const isContentContainerOnScreen = useOnScreen(contentContainerRef, '-20px', true);

  if (!settings) {
    return null;
  }

  const pose = ifElse(
    equals(true),
    always(isContentContainerOnScreen ? POSES.VISIBLE_MOBILE : POSES.INIT_MOBILE),
    always(isContentContainerOnScreen ? POSES.VISIBLE : POSES.INIT)
  )(isMobile);

  const {
    description,
    firstContentBox,
    secondContentBox,
    thirdContentBox,
    hqLocation,
    mapMarker,
    mapLinkLabel,
  } = settings;
  const renderDescription = renderWhenTrue(() => <Description>{description}</Description>);

  return (
    <Container id="contact">
      <Content>
        <ContentContainer ref={contentContainerRef} pose={pose}>
          {renderDescription(!!description)}
          <ContentBox>
            <Text>{firstContentBox}</Text>
          </ContentBox>
          <ContentBox>
            <Text>{secondContentBox}</Text>
          </ContentBox>
          <ContentBox>
            <Text>{thirdContentBox}</Text>
          </ContentBox>
        </ContentContainer>
      </Content>
      <MapWrapper>
        <Map latitude={hqLocation.lat} longitude={hqLocation.lon} marker={mapMarker} mapLinkLabel={mapLinkLabel} />
      </MapWrapper>
    </Container>
  );
};
