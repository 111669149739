import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactMapGL, { Marker } from 'react-map-gl';
import { omit } from 'ramda';

import { selectAssets } from '../../../../modules/contentRegistry';
import { useWindowResize } from '../../../utils/hooks';
import { getAssetPath } from '../../../utils/assets';
import { Container, MarkerImg, Link, GoogleMapsLink } from './map.styles';
import { MAP_CONFIGURATION } from './map.constants';
import { renderWhenTrue } from '../../../utils/rendering';

export const getGoogleMapsLinkProps = (lat, long) => ({
  href: `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
  target: '_blank',
  rel: 'noopener noreferrer',
});

export const Map = ({ latitude = 0, longitude = 0, marker, mapLinkLabel }) => {
  const [settings, setSettings] = React.useState({ latitude, longitude });
  const assets = useSelector(selectAssets);
  const markerSrc = getAssetPath(assets, marker);
  const googleMapsLinkProps = getGoogleMapsLinkProps(latitude, longitude);

  const handleViewportChange = viewport => {
    setSettings(omit(['width', 'height'], viewport));
  };

  useWindowResize(() => {
    setSettings(omit(['width', 'height'], settings));
  });

  const renderGoogleMapsLink = renderWhenTrue(() => (
    <GoogleMapsLink {...googleMapsLinkProps}>{mapLinkLabel}</GoogleMapsLink>
  ));

  return (
    <Container>
      <ReactMapGL
        width="100%"
        height="100%"
        {...MAP_CONFIGURATION}
        {...settings}
        onViewportChange={handleViewportChange}
      >
        {renderGoogleMapsLink(!!mapLinkLabel)}
        <Marker latitude={latitude} longitude={longitude} offsetLeft={-12} offsetTop={-20}>
          <Link {...googleMapsLinkProps}>
            <MarkerImg src={markerSrc} alt="Map marker - Hyper logo" />
          </Link>
        </Marker>
      </ReactMapGL>
    </Container>
  );
};

Map.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  marker: PropTypes.string.isRequired,
  mapLinkLabel: PropTypes.string,
};
