import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container } from './scrollTo.styles';
import { whenElementExist, scrollToRef } from '../../../utils/scroll';

export const ScrollTo = ({ values: { id, text } }) => {
  const handleClick = useCallback(() => whenElementExist(id, scrollToRef), [id]);

  return <Container onClick={handleClick}>{text}</Container>;
};

ScrollTo.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};
