import React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';

import { H1, H2, H3, H4, H5, H6 } from '../../../../theme/typography';

export const renderers = map(Component => props => <Component {...props} />)({
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
});

export const Heading = ({ children, level = 2 }) => {
  const Component = renderers[`H${level}`];

  return <Component>{children}</Component>;
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};
