import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';

import { renderers } from './text.renderers';
import { tagsShortcode, scrollToShortcode, buttonShortcode } from './text.plugins';

export const Text = ({ className, children }) => (
  <ReactMarkdown
    source={children}
    renderers={renderers}
    escapeHtml={false}
    className={className}
    plugins={[tagsShortcode, scrollToShortcode, buttonShortcode]}
  />
);

Text.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};
