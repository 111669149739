import styled, { css } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Image as Img } from '../image';
import { media } from '../../../theme/media';
import { colors } from '../../../theme/styled';
import { paragraphsWidthStyle, getHeightStyles } from '../../../theme/typography';
import { Container as SectionContainer } from '../section/section.styles';

export const Container = styled(SectionContainer)``;

export const Image = styled(Img)`
  max-width: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Background = styled.div`
  display: flex;
  width: 100%;
  z-index: 1;
  ${getHeightStyles()};

  ${media.tabletLandscape`
    position: absolute;
    min-height: 100%;
  `};

  &:empty {
    background-color: ${colors.grey};
  }
`;

const dotsSizesStyles = css`
  width: 10px;
  height: 10px;

  ${media.desktopWide`  
    width: 20px;
    height: 20px;
  `};
`;

const dotsStyles = css`
  .slick-dots {
    text-align: left;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    ${paragraphsWidthStyle}
  }

  .slick-dots li {
    margin: 0 20px 0 0;
  }

  .slick-dots li,
  .slick-dots li button,
  .slick-dots li button:before {
    ${dotsSizesStyles};
  }

  .slick-dots li button:before {
    content: '';
    opacity: 1;
    border-radius: 50%;
    border: 1px solid ${colors.primary};
  }

  .slick-dots li.slick-active button:before {
    background-color: ${colors.primary};
  }
`;

export const SliderWrapper = styled.div`
  color: ${colors.primary};
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slider.slick-initialized {
    width: 100%;
    height: 100%;

    ${media.tablet`  
      width: 50%;
      margin-right: 50%;
    `};
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-active {
    z-index: 1;
  }

  ${dotsStyles}
`;
