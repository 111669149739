import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAssets } from '../../../modules/contentRegistry';
import { getAssetPath, getImageAlt, getSrcSet } from '../../utils/assets';
import { Img } from './image.styles';

export const Image = ({ image, className }) => {
  const assets = useSelector(selectAssets);
  const refElement = useRef();

  useEffect(() => {
    objectFitPolyfill(refElement.current);
  }, []);

  const { imageSrc, alt, srcSet } = useMemo(() => {
    const imageSrc = getAssetPath(assets, image);

    return {
      imageSrc,
      alt: getImageAlt(assets, image),
      srcSet: getSrcSet(imageSrc),
    };
  }, [assets, image]);

  return <Img className={className} src={imageSrc} srcSet={srcSet} alt={alt} ref={refElement} />;
};

Image.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
};
