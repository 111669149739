import styled from 'styled-components';

import { colors } from '../../../theme/styled';
import { H1, getHeightStyles } from '../../../theme/typography';
import { Image as Img } from '../image';
import { Video as VideoComponent } from '../video';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  ${getHeightStyles({ dynamic: false })};
`;

export const Video = styled(VideoComponent)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
`;

export const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Title = styled(H1)`
  color: ${colors.white};
`;

export const Image = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
