import styled, { css, keyframes } from 'styled-components';

import { colors, cursorUrls } from '../../../theme/styled';
import { media } from '../../../theme/media';
import { getHeightStyles, sectionWidthStyles, Section, sectionMarginStyles } from '../../../theme/typography';
import { AnimatedHandle } from './imagesComparator.posed';

export const Container = styled(Section)`
  width: calc(100% - 20px);
  overflow: hidden;
  ${getHeightStyles({ dynamic: false })};
  ${sectionWidthStyles};
  ${sectionMarginStyles};

  & > *,
  img {
    height: 100% !important;
    object-fit: cover;
    margin-top: 0;
  }
`;

const arrowStyles = css`
  content: '';
  position: relative;
  display: inline-block;
  box-shadow: 1px -1px 0 0 ${colors.accent} inset;
  padding: 3px;
  margin: 4px;

  ${media.tablet`
    padding: 4px;
    margin: 7px;
  `}

  ${media.desktop`
    padding: 8px;
    margin: 15px;
  `}
`;

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${colors.white40};
  }
  70% {
    box-shadow: 0 0 0 20px ${colors.whiteTransparent};
  }
  100% {
    box-shadow: 0 0 0 0 ${colors.whiteTransparent};
  }
`;

export const Handle = styled(AnimatedHandle)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.white};
  height: 38px;
  width: 38px;
  animation: ${pulseAnimation} 2s infinite;

  &,
  &:before,
  &:after {
    cursor: url(${cursorUrls.pointer}) 12 12, auto !important;
  }

  ${media.tablet`
    height: 60px;
    width: 60px;
  `}

  ${media.desktop`
    height: 118px;
    width: 118px;
  `}

  &:before {
    ${arrowStyles};
    transform: rotate(45deg);
  }

  &:after {
    ${arrowStyles};
    transform: rotate(225deg);
  }
`;
