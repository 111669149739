import React from 'react';
import { omit } from 'ramda';

import { TEXT_ALIGN } from '../../../modules/contentRegistry/contentRegistry.constants';
import { ContentRegistryPropTypes } from '../../../modules/contentRegistry';
import { colors } from '../../../theme/styled';
import { renderWhenTrue } from '../../utils/rendering';
import { Video } from '../video';
import { Background, Container, Content, Image, SectionParagraph } from './section.styles';

export const Section = ({ elementId, image, video, text, textAlign, textColor, textBackgroundColor }) => {
  const isBackgroundVisible = textBackgroundColor !== colors.transparent;
  const textProps = { textAlign, textColor, textBackgroundColor };
  const renderImage = renderWhenTrue(() => <Image image={image} />);
  const renderVideo = renderWhenTrue(() => <Video video={video} />);
  const isImageVisible = !(textAlign === TEXT_ALIGN.CENTER && textBackgroundColor !== colors.transparent);

  return (
    <Container id={elementId} textAlign={textAlign} isBackgroundVisible={isBackgroundVisible}>
      <Content {...textProps}>
        <SectionParagraph textAlign={textAlign}>{text}</SectionParagraph>
      </Content>
      <Background isAbsolute={textBackgroundColor === 'transparent'}>
        {renderImage(!!image && !video && isImageVisible)}
        {renderVideo(!!video)}
      </Background>
    </Container>
  );
};

Section.propTypes = {
  ...omit(['sys', 'name'], ContentRegistryPropTypes.section),
};
