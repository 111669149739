import React from 'react';
import PropTypes from 'prop-types';

import { Container, Line } from './hamburger.styles';

export const Hamburger = ({ open = false, onClick }) => {
  return (
    <Container onClick={onClick} open={open}>
      <Line />
    </Container>
  );
};

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
