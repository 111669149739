export const SLIDER_SETTINGS = {
  speed: 500,
  autoplaySpeed: 10000,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  fade: false,
  swipe: false,
  draggable: false,
  touchMove: false,
  focusOnSelect: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};
