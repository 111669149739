import { createSelector } from 'reselect';
import {
  compose,
  head,
  prop,
  values,
  pipe,
  pick,
  propOr,
  map,
  pickBy,
  find,
  propEq,
  cond,
  T,
  equals,
  props,
  flatten,
  filter,
  path,
  includes,
  not,
  pathOr,
  uniq,
  isNil,
  reject,
} from 'ramda';

const getComponentName = pathOr('Unknown', ['sys', 'contentType', 'sys', 'id']);

const getImageAssetPropByModelType = cond([
  [equals('testimonialsSections'), () => prop('backgroundImage')],
  [equals('imagesComparator'), () => props(['firstImage', 'secondImage'])],
  [T, () => prop('image')],
]);

export const selectContentRegistryDomain = state => state.contentRegistry;

export const selectEntities = createSelector(
  selectContentRegistryDomain,
  prop('entities')
);

export const selectAssetsLoaded = createSelector(
  selectContentRegistryDomain,
  prop('assetsLoaded')
);

export const selectLocation = createSelector(
  selectContentRegistryDomain,
  prop('location')
);

export const selectCookies = createSelector(
  selectContentRegistryDomain,
  prop('cookies')
);

export const selectGTM = createSelector(
  selectContentRegistryDomain,
  prop('gtm')
);

export const selectAssets = createSelector(
  selectEntities,
  prop('assets')
);

export const selectImages = createSelector(
  selectAssets,
  pickBy(asset => /image/.test(asset.file.contentType))
);

export const selectVideos = createSelector(
  selectAssets,
  pickBy(asset => /video/.test(asset.file.contentType))
);

export const selectPages = createSelector(
  selectEntities,
  prop('pages')
);

export const selectSections = createSelector(
  selectEntities,
  prop('sections')
);

export const selectImagesComparators = createSelector(
  selectEntities,
  prop('imagesComparators')
);

export const selectHeroes = createSelector(
  selectEntities,
  prop('heroes')
);

export const selectTestimonialsSections = createSelector(
  selectEntities,
  prop('testimonialsSections')
);

export const selectTestimonials = createSelector(
  selectEntities,
  prop('testimonials')
);

export const selectContentItems = createSelector(
  selectSections,
  selectImagesComparators,
  selectHeroes,
  selectTestimonialsSections,
  selectTestimonials,
  (sections, imagesComparators, heroes, testimonialsSection, testimonials) => ({
    ...sections,
    ...imagesComparators,
    ...heroes,
    ...testimonialsSection,
    ...testimonials,
  })
);

export const selectCurrentPageContentAssetsId = createSelector(
  selectPages,
  selectContentItems,
  selectLocation,
  (pages, contentItems, location) =>
    pipe(
      values,
      find(propEq('url', location)),
      prop('content'),
      content =>
        map(itemId => {
          const item = contentItems[itemId];
          return pipe(
            getComponentName,
            getImageAssetPropByModelType,
            get => get(item)
          )(item);
        })(content),
      flatten,
      uniq,
      reject(isNil)
    )(pages)
);

export const selectCurrentAssets = createSelector(
  selectAssets,
  selectCurrentPageContentAssetsId,
  (assets, assetsId) => map(id => assets[id])(assetsId)
);

export const selectOthersAssets = createSelector(
  selectImages,
  selectCurrentPageContentAssetsId,
  (images, currentAssetsIds) =>
    pipe(
      values,
      filter(image =>
        pipe(
          path(['sys', 'id']),
          id => includes(id, currentAssetsIds),
          not
        )(image)
      )
    )(images)
);

export const selectSettings = createSelector(
  selectEntities,
  compose(
    head,
    values,
    prop('settings')
  )
);

export const selectAnchorLinks = createSelector(
  selectEntities,
  prop('anchorLinks')
);

export const selectMenuItems = createSelector(
  selectSettings,
  selectPages,
  selectAnchorLinks,
  (settings = {}, pages = {}, anchorLinks = {}) => {
    const dataMap = { ...pages, ...anchorLinks };
    return pipe(
      pick(propOr([], 'menuItems', settings)),
      values,
      map(pick(['menuLabel', 'url', 'elementId']))
    )(dataMap);
  }
);
