import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getDisplayComponent } from './utils';
import { selectContentItems } from '../../../modules/contentRegistry/contentRegistry.selectors';

const renderContentComponent = item => {
  const DisplayComponent = getDisplayComponent(item);
  if (DisplayComponent) {
    return <DisplayComponent {...item} key={item.sys.id} />;
  }

  return null;
};

export const ContentManager = memo(({ content }) => {
  const contentItems = useSelector(selectContentItems);

  if (!content) {
    return null;
  }

  if (Array.isArray(content)) {
    return <>{content.map(itemId => renderContentComponent(contentItems[itemId]))}</>;
  }

  return <>{renderContentComponent(content)}</>;
});

ContentManager.propTypes = {
  content: PropTypes.array,
};
