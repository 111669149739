import styled, { css, keyframes } from 'styled-components';
import { prop } from 'ramda';

import { styleWhenTrue } from '../../../utils/rendering';
import { cursorUrls, colors } from '../../../../theme/styled';

export const Line = styled.span`
  display: block;
  position: relative;

  &:before,
  &:after {
    will-change: transform;
    transition: transform 300ms 50ms ease;
    position: absolute;
    background: ${colors.primary};
    width: 25px;
    height: 1px;
    content: '';
    left: 0;
  }

  &:before {
    transform: translateY(5px);
  }

  &:after {
    transform: translateY(15px);
  }
`;

const collapseTopLine = keyframes`
  50%  {transform: translateY(10px)}
  100%  {transform: translateY(10px) rotate(45deg)}
`;

const collapseBottomLine = keyframes`
  50%  {transform: translateY(10px)}
  100%  {transform: translateY(10px) rotate(-45deg)}
`;

const openingTopLine = keyframes`
  0%  {transform: translateY(10px) rotate(45deg)}
  50%  {transform: translateY(10px) rotate(0deg)}
  100%  {transform: translateY(5px)}
`;

const openingBottomLine = keyframes`
  0%  {transform: translateY(10px) rotate(-45deg)}
  50%  {transform: translateY(10px) rotate(0deg)}
  100%  {transform: translateY(15px)}
`;

const openStyles = css`
  ${Line} {
    &:before {
      animation: ${collapseTopLine} 300ms ease forwards;
    }
    &:after {
      animation: ${collapseBottomLine} 300ms ease forwards;
    }
  }
`;

const closeStyles = css`
  ${Line} {
    &:before {
      animation: ${openingTopLine} 300ms ease forwards;
    }
    &:after {
      animation: ${openingBottomLine} 300ms ease forwards;
    }
  }
`;

export const Container = styled.div`
  cursor: url(${cursorUrls.pointer}) 12 12, auto !important;
  width: 25px;
  height: 20px;

  ${styleWhenTrue(prop('open'), openStyles, closeStyles)}
`;
