import styled from 'styled-components';

import { metrics } from '../../theme/styled';
import { media } from '../../theme/media';

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${metrics.mainMenuHeight.mobile}px;
  overflow: hidden;

  ${media.desktop`
    margin-top: ${metrics.mainMenuHeight.desktop}px;
  `};

  ${media.desktopWide`
    margin-top: ${metrics.mainMenuHeight.desktopWide}px;
  `};

  ${media.desktopFull`
    margin-top: ${metrics.mainMenuHeight.desktopFull}px;
  `};
`;
