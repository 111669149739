import styled from 'styled-components';
import { media } from '../../../theme/media';
import { colors, metrics, cursorUrls, fontSizes } from '../../../theme/styled';
import { sectionWidthStyles } from '../../../theme/typography';

import { Text as TextComponent } from '../text';

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  width: 100%;
  height: ${metrics.cookiesBar.mobile}px;
  z-index: 3;
  bottom: 0;

  ${media.tablet`
    height: ${metrics.cookiesBar.desktop}px;
  `}

  ${media.desktopWide`
    height: ${metrics.cookiesBar.desktopWide}px;
  `}

  ${media.desktopFull`
    height: ${metrics.cookiesBar.desktopFull}px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  ${sectionWidthStyles};

  ${media.tablet`
    flex-direction: row;
  `}
`;

export const Text = styled(TextComponent)`
  padding-right: 35%;
  margin-bottom: 11px;

  ${media.tablet`
    padding-right: 0;
    margin-bottom: 0;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

export const TextButton = styled.button`
  background: transparent;
  font-size: ${fontSizes.paragraph.mobile};
  border: 0;

  ${media.desktopWide`
    font-size: ${fontSizes.paragraph.desktopWide};
  `};
`;

export const CrossButton = styled.i`
  position: absolute;
  top: 4px;
  right: 0;
  width: 1.333em;
  height: 1.333em;
  cursor: url(${cursorUrls.pointer}) 12 12, auto !important;

  &:before,
  &:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 1.333em;
    width: 1px;
    background-color: ${colors.primary};
    cursor: url(${cursorUrls.pointer}) 12 12, auto !important;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  ${media.tablet`
    margin-left: 36px;
    position: relative;
    top: auto;
    right: auto;
  `}

  ${media.desktopWide`
    width: 25px;
    height: 25px;
    
    &:before,
    &:after {
      height: 25px;
    }
  `}
`;
