export const HERO_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const TEXT_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};
