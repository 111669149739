import React from 'react';
import { PoseGroup } from 'react-pose';
import { useSelector } from 'react-redux';

import { renderWhenTrue } from '../../utils/rendering';
import { selectAssetsLoaded } from '../../../modules/contentRegistry';
import { AnimatedContainer, HyperLogo } from './loader.styles';

const renderLoader = renderWhenTrue(() => (
  <AnimatedContainer key="loader">
    <HyperLogo />
  </AnimatedContainer>
));

export const Loader = () => {
  const assetsLoaded = useSelector(selectAssetsLoaded);

  return <PoseGroup>{renderLoader(!assetsLoaded)}</PoseGroup>;
};
