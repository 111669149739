import { createClient } from 'contentful';

export const getEnv = env => process.env[env];

export default {
  getClient: () => {
    return createClient({
      environment: getEnv('REACT_APP_CONTENTFUL_ENVIRONMENT'),
      space: getEnv('REACT_APP_CONTENTFUL_SPACE_ID'),
      accessToken: getEnv('REACT_APP_CONTENTFUL_ACCESS_TOKEN'),
    });
  },
};
