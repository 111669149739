import { css } from 'styled-components';

export const sizes = {
  desktopFull: 1920,
  desktopWide: 1440,
  desktop: 1280,
  tablet: 768,
  mobile: 320,
};

export const QUAD_HD = 2560;

export const IMAGES_SIZES_ARRAY = Object.values(sizes).sort((a, b) => a - b);

export const getWindowWidth = () => window.innerWidth;

export const getDevicePixelRatio = () => window.devicePixelRatio;

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  acc[`${label}Retina`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      ${css(...args)}
    }
  `;
  acc[`${label}Landscape`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (orientation: landscape) {
      ${css(...args)}
    }
  `;
  acc[`${label}Portrait`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (orientation: portrait) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const isMobile = () => getWindowWidth() < sizes.tablet;

export const isTablet = () => {
  const width = getWindowWidth();
  return width >= sizes.tablet && width < sizes.desktop;
};

export const isDesktop = () => getWindowWidth() >= sizes.desktop;

export const isPortrait = () => window.matchMedia('(orientation: portrait)').matches;

export const isLandscape = () => !isPortrait;
