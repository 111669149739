import { useEffect, useContext, useState, useRef } from 'react';
import 'intersection-observer';

import { isMobile } from '../../theme/media';
import { RouterContext } from './appRouter';

const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useWindowResize = callback => {
  return useWindowEvent('resize', callback);
};

export const useWindowScroll = callback => {
  return useWindowEvent('scroll', callback);
};

export const useRouter = () => {
  return useContext(RouterContext);
};

export const useMobileDetect = () => {
  const [isMobileDevice, setIsMobile] = useState(isMobile());

  useWindowResize(() => {
    const newIsMobile = isMobile();
    if (isMobileDevice !== newIsMobile) {
      setIsMobile(newIsMobile);
    }
  });

  return isMobileDevice;
};

// Source: https://usehooks.com/useOnScreen/
export const useOnScreen = (ref, rootMargin = '0px', onlyFirstAppearing = false) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  const prevIsIntersecting = useRef(false);

  const handleChange = value => {
    if (!(onlyFirstAppearing && prevIsIntersecting.current)) {
      prevIsIntersecting.current = value;
      setIntersecting(value);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        handleChange(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.unobserve(ref.current);
    };
  }, []);

  return isIntersecting;
};
