import { normalize } from 'normalizr';
import {
  concat,
  filter,
  keys,
  length,
  lt,
  mapObjIndexed,
  pipe,
  without,
  path,
  cond,
  always,
  both,
  gt,
  lte,
  ifElse,
  isEmpty,
} from 'ramda';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import Contentful from '../../shared/services/contentful';
import { StartupTypes } from '../startup/startup.redux';
import { ContentRegistryActions, ContentRegistryTypes } from './contentRegistry.redux';
import { entryArraySchema } from './contentRegistry.schema';
import { selectCurrentAssets, selectOthersAssets } from './contentRegistry.selectors';
import { preloadImage, IMAGE_QUALITY } from '../../shared/utils/assets';
import { getWindowWidth, getDevicePixelRatio, sizes, QUAD_HD } from '../../theme/media';

const defaultSyncParams = {
  resolveLinks: false,
};

const LOCAL_STORAGE_COOKIES_KEY = 'hyper_cookies_was_accepted';

const getImagesWidth = () =>
  cond([
    [gt(sizes.mobile), always(sizes.tablet)],
    [both(lte(sizes.mobile), gt(sizes.tablet)), always(sizes.desktop)],
    [both(lte(sizes.tablet), gt(sizes.desktop)), always(sizes.desktopWide)],
    [both(lte(sizes.desktop), gt(sizes.desktopWide)), always(sizes.desktopFull)],
    [lte(sizes.desktopWide), always(QUAD_HD)],
  ])(getWindowWidth() * getDevicePixelRatio());

export function* loadAssets() {
  try {
    const assets = yield select(selectCurrentAssets);

    const getAdditionalAssetsSuffix = ifElse(isEmpty, always(''), width => `?w=${width}&q=${IMAGE_QUALITY}`)(
      getImagesWidth()
    );
    yield Promise.all(assets.map(image => preloadImage(`${path(['file', 'url'])(image)}${getAdditionalAssetsSuffix}`)));
    yield put(ContentRegistryActions.loadAssetsSuccess());

    const othersAssets = yield select(selectOthersAssets);
    return yield Promise.all(
      othersAssets.map(image => preloadImage(`${path(['file', 'url'])(image)}${getAdditionalAssetsSuffix}`))
    );
  } catch (e) {
    return yield put(ContentRegistryActions.loadAssetsError());
  }
}

function* getContent() {
  const client = yield Contentful.getClient();
  const syncParams = { ...defaultSyncParams, initial: true };
  const cookies = JSON.parse(localStorage.getItem(LOCAL_STORAGE_COOKIES_KEY));

  const { assets, entries } = yield client.sync(syncParams);
  const normalizedData = normalize(concat(entries, assets), entryArraySchema);
  const hasAnyFields = pipe(
    keys,
    without('sys'),
    length,
    lt(0)
  );
  const filterNonEmptyEntities = mapObjIndexed(filter(hasAnyFields));

  delete normalizedData.entities.links;
  const entities = filterNonEmptyEntities(normalizedData.entities);

  yield put(ContentRegistryActions.addEntities(entities));
  yield put(ContentRegistryActions.loadAssets());
  yield put(ContentRegistryActions.setCookiesSuccess(cookies ? cookies.isDisabled : false));
  yield put(ContentRegistryActions.setGTM(cookies ? cookies.isAccepted : false));
}

function* setCookies({ isCookiesAccepted }) {
  localStorage.setItem(LOCAL_STORAGE_COOKIES_KEY, `{ "isDisabled": "true", "isAccepted": "${isCookiesAccepted}" }`);
  yield put(ContentRegistryActions.setCookiesSuccess(true));
  yield put(ContentRegistryActions.setGTM(isCookiesAccepted));
}

export function* watchContentRegistry() {
  yield all([
    takeLatest(StartupTypes.STARTUP, getContent),
    takeLatest(ContentRegistryTypes.LOAD_ASSETS, loadAssets),
    takeLatest(ContentRegistryTypes.SET_COOKIES, setCookies),
  ]);
}
