import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pipe, values, propEq, find, isEmpty } from 'ramda';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectAssets, selectPages, ContentRegistryActions } from '../../modules/contentRegistry';
import { getAssetPath } from '../../shared/utils/assets';
import { ContentManager } from '../../shared/components/contentManager';
import { Container } from './home.styles';

const getPageByUrl = (pages, url) =>
  pipe(
    values,
    find(propEq('url', url))
  )(pages);

export const HomeComponent = ({ history }) => {
  const dispatch = useDispatch();
  const pages = useSelector(selectPages);
  const assets = useSelector(selectAssets);
  let [pageData, setPageData] = useState(null);
  const { socialImage, url } = useMemo(
    () => ({
      socialImage: getAssetPath(assets, pageData && pageData.socialImage),
      url: `${process.env.REACT_APP_PAGE_URL}${history.location.pathname}`,
    }),
    [pageData, assets, history.location.pathname]
  );

  useEffect(() => {
    const page = getPageByUrl(pages, history.location.pathname);

    if (!isEmpty(pages) && !page) {
      history.push('/404');
    }
    setPageData(page);
  }, [pages, history.location.key]);

  useEffect(() => {
    dispatch(ContentRegistryActions.setLocation(history.location.pathname));

    if (window) {
      window.scrollTo(0, 0);
    }
  }, [history.location.key]);

  if (!pageData) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>{pageData.metaTitle}</title>
        <meta name="description" content={pageData.metaDescription} />
        <meta property="og:site_name" content="hyper" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageData.metaTitle} />
        <meta property="og:description" content={pageData.metaDescription} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={socialImage} />
        <meta name="twitter:card" content="summary" />
        <link rel="canonical" href={url} />
      </Helmet>
      <ContentManager content={pageData.content} />
    </Container>
  );
};

HomeComponent.propTypes = {
  history: PropTypes.object.isRequired,
};

export const Home = injectIntl(HomeComponent);
