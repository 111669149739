/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'notFound.pageTitle',
    defaultMessage: 'Not found',
  },
  title: {
    id: 'notFound.title',
    defaultMessage: 'Page not found',
  },
  goToHomepage: {
    id: 'notFound.gotoHomepage',
    defaultMessage: 'Go to Homepage',
  },
});
