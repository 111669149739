import React from 'react';
import { map, pathOr } from 'ramda';

import { Paragraph, Strong, Link, Hr, Blockquote } from '../../../theme/typography';
import { List } from './list';
import { Tags } from './tags';
import { Heading } from './heading';
import { ScrollTo } from './scrollTo';
import { Button } from './button';

const withStyledRenderer = StyledComponent => {
  return props => <StyledComponent {...props} />;
};

const EnhancedParagraph = props => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const hasSingleChild = children && children[0] && children.length === 1;
  const hasInvalidStructure = hasSingleChild && pathOr(null, ['0', 'props', 'values'], children);

  if (hasInvalidStructure) {
    return children;
  }

  return <Paragraph {...props} />;
};

const EnhancedLink = props => <Link {...props} target="_blank" rel="noopener noreferrer" />;

export const renderers = map(withStyledRenderer)({
  link: EnhancedLink,
  strong: Strong,
  paragraph: EnhancedParagraph,
  thematicBreak: Hr,
  list: List,
  tags: Tags,
  scrollTo: ScrollTo,
  button: Button,
  heading: Heading,
  blockquote: Blockquote,
});
