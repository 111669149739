import { schema } from 'normalizr';
import { mapObjIndexed, prop } from 'ramda';

export const schemaVersion = '20';

const idAttribute = ({ sys: { id } }) => id;
const processStrategy = ({ fields, sys }) => ({
  ...mapObjIndexed(prop('en-US'))(fields),
  sys,
});

export const linkSchema = new schema.Entity(
  'links',
  {},
  {
    idAttribute,
  }
);

export const assetSchema = new schema.Entity(
  'assets',
  {},
  {
    idAttribute,
    processStrategy,
  }
);

export const pageSchema = new schema.Entity(
  'pages',
  {
    hero: linkSchema,
    socialImage: linkSchema,
    content: [linkSchema],
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const sectionSchema = new schema.Entity(
  'sections',
  {
    image: assetSchema,
    video: assetSchema,
    ctaReference: pageSchema,
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const anchorLinkSchema = new schema.Entity(
  'anchorLinks',
  {},
  {
    idAttribute,
    processStrategy,
  }
);

export const settingsSchema = new schema.Entity(
  'settings',
  {
    menuItems: [linkSchema],
    mapMarker: assetSchema,
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const imagesComparatorSchema = new schema.Entity(
  'imagesComparators',
  {
    firstImage: assetSchema,
    secondImage: assetSchema,
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const heroesSchema = new schema.Entity(
  'heroes',
  {
    image: assetSchema,
    video: assetSchema,
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const testimonialsSectionSchema = new schema.Entity(
  'testimonialsSections',
  {
    testimonials: [linkSchema],
    backgroundImage: assetSchema,
  },
  {
    idAttribute,
    processStrategy,
  }
);

export const testimonialSchema = new schema.Entity(
  'testimonials',
  {},
  {
    idAttribute,
    processStrategy,
  }
);

export const entrySchema = new schema.Union(
  {
    anchorLink: anchorLinkSchema,
    asset: assetSchema,
    section: sectionSchema,
    page: pageSchema,
    settings: settingsSchema,
    imagesComparator: imagesComparatorSchema,
    hero: heroesSchema,
    testimonialsSection: testimonialsSectionSchema,
    testimonial: testimonialSchema,
  },
  value => {
    if (value.sys.type === 'Asset') {
      return 'asset';
    }

    return value.sys.contentType.sys.id;
  }
);

export const entryArraySchema = new schema.Array(entrySchema);
