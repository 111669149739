import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectMenuItems } from '../../../modules/contentRegistry/contentRegistry.selectors';
import { ROUTES } from '../../../routes/routes';
import { useWindowResize, useRouter, useMobileDetect } from '../../utils/hooks';
import { MenuItem } from './menuItem';
import { Hamburger } from './hamburger';
import { Container, Menu, MobileBar, HyperLogo, AnimatedLink, AnimatedMenu } from './mainMenu.styles';
import { renderWhenTrue, renderWhenTrueOtherwise } from '../../utils/rendering';

const renderMobileMenuItems = (items, onAnchorClick) =>
  items.map(props => (
    <AnimatedLink key={props.url || props.elementId}>
      <MenuItem {...props} onClick={onAnchorClick} />
    </AnimatedLink>
  ));

const renderMenuItems = items => items.map(props => <MenuItem key={props.url || props.elementId} {...props} />);

const renderMenu = (items, isMobile, isOpen, onAnchorClick) =>
  renderWhenTrueOtherwise(
    () => (
      <AnimatedMenu initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
        {renderMobileMenuItems(items, onAnchorClick)}
      </AnimatedMenu>
    ),
    () => <Menu>{renderMenuItems(items)}</Menu>
  )(isMobile);

export const MainMenu = () => {
  const menuItems = useSelector(selectMenuItems);
  const { location } = useRouter();
  const isMobile = useMobileDetect();
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  useWindowResize(() => {
    if (!isMobile && isMenuOpen) {
      setMenuOpen(false);
    }
  });

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  const handleHamburgerClick = useCallback(() => setMenuOpen(!isMenuOpen), [setMenuOpen, isMenuOpen]);

  const handleAnchorClick = useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const renderHamburger = renderWhenTrue(() => <Hamburger onClick={handleHamburgerClick} open={isMenuOpen} />);

  return (
    <Container>
      <MobileBar>
        <Link aria-label="Hyper logo" to={ROUTES.home}>
          <HyperLogo />
        </Link>
        {renderHamburger(isMobile)}
      </MobileBar>
      {renderMenu(menuItems, isMobile, isMenuOpen, handleAnchorClick)}
    </Container>
  );
};

MainMenu.propTypes = {};
