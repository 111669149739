import { combineReducers } from 'redux';

import { reducer as startupReducer } from './startup/startup.redux';
import { reducer as contentRegistryReducer } from './contentRegistry/contentRegistry.redux';
//<-- IMPORT MODULE REDUCER -->

export default function createReducer() {
  return combineReducers({
    startup: startupReducer,
    contentRegistry: contentRegistryReducer,
    //<-- INJECT MODULE REDUCER -->
  });
}
