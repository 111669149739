import posed from 'react-pose';
import { colors } from '../../../theme/styled';
import { POSES } from '../../../theme/animated';

export const AnimatedHandle = posed.div({
  pressable: true,
  press: {
    animation: 'none',
    boxShadow: `0 0 0 0 ${colors.whiteTransparent})`,
    scale: 0.95,
  },
  [POSES.INIT]: {
    scale: 1,
  },
});
