import styled, { css } from 'styled-components';
import { cond, equals, prop, always, curry } from 'ramda';

import { TEXT_ALIGN } from '../../../modules/contentRegistry/contentRegistry.constants';
import { colors, metrics } from '../../../theme/styled';
import {
  Paragraph,
  Section,
  paragraphsWidthStyle,
  sectionWidthStyles,
  UnorderedListItem,
} from '../../../theme/typography';
import { Button } from '../text/button';
import { Image as ImageComponent } from '../image';
import { media } from '../../../theme/media';
import { Text } from '../text';
import { styleWhenTrue } from '../../utils/rendering';

const StyledButton = styled(Button);

const getColorByName = curry((defaultValue, propName, props) => {
  const selectedColor = prop(propName, props);
  return colors[selectedColor] || defaultValue;
});

export const Content = styled.div`
  background-color: ${getColorByName(colors.secondary, 'textBackgroundColor')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${getColorByName(colors.primary, 'textColor')};
  z-index: 2;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${metrics.mainMenuHeight.mobile}px);

  ${Paragraph}, ${StyledButton} {
    color: inherit;
  }

  ${UnorderedListItem} {
    &::before {
      background-color: ${getColorByName(colors.primary, 'textColor')};
    }
  }

  ${media.tablet`
    margin-bottom: 0;
    width: 50%;
  `};

  ${media.tabletPortrait`
    width: 100%;
    min-height: calc((100vh - ${metrics.mainMenuHeight.mobile}px) / 2);
  `}
`;

export const SectionParagraph = styled(Text)`
  margin: 36px 0;
  ${paragraphsWidthStyle}
`;

export const Image = styled(ImageComponent)`
  max-width: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Background = styled.div`
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative')};
  display: flex;
  width: 100%;
  height: calc(100vh - ${metrics.mainMenuHeight.mobile}px);
  min-height: 100%;

  ${media.tabletPortrait`
    height: 100%;
    min-height: calc((100vh - ${metrics.mainMenuHeight.mobile}px) / 2);
  `}

  ${media.tabletLandscape`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  `};

  video {
    max-width: 100%;
    width: auto;
    height: auto;
  }
`;

const textPositionRight = css`
  ${media.tablet`
    flex-direction: row-reverse;
  `};

  ${media.tabletLandscape`
    ${Background} {
      left: 0;
    }
  `};
`;

const textPositionCenter = css`
  ${Content} {
    position: absolute;
    flex: none;
    width: 100%;
  }

  ${Background} {
    width: 100%;

    &:empty {
      background-color: ${colors.transparent};
    }
  }

  ${Image} {
    width: 100%;
    object-fit: cover;
  }

  ${SectionParagraph} {
    width: 100%;
    padding: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${SectionParagraph} > * {
    width: 100%;
    text-align: center;
  }

  ${SectionParagraph} > *:not(a, h2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  ${media.tablet`
    ${Content} {
      flex: 1;
    }
  `}
`;

const getTextStyles = props =>
  cond([
    [equals(TEXT_ALIGN.RIGHT), always(textPositionRight)],
    [equals(TEXT_ALIGN.CENTER), always(textPositionCenter)],
  ])(prop('textAlign', props));

const backgroundVisibleStyles = css`
  ${Background} {
    ${media.tabletLandscape`
    width: 50%;
  `};
  }
`;

const backgroundInvisibleStyles = css`
  ${Content} {
    ${media.tablet`
      width: 50%;
      position: static;
    `};
  }

  ${Background} {
    ${media.tablet`
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
  `};
  }
`;

export const Container = styled(Section)`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  position: relative;
  overflow: hidden;

  ${media.tablet`
    flex-direction: row;
  `};

  ${sectionWidthStyles}
  ${getTextStyles}
  ${styleWhenTrue(prop('isBackgroundVisible'), backgroundVisibleStyles, backgroundInvisibleStyles)}
`;
