import React, { useMemo } from 'react';
import { always, ifElse, isEmpty, pick } from 'ramda';

import { ContentRegistryPropTypes } from '../../../../modules/contentRegistry';
import { Container, Quotation, Caption, Company } from './testimonial.styles';

const renderCompany = company => ifElse(isEmpty, always(null), () => <Company>{company}</Company>)(company);

const renderCaption = caption => {
  const captionStartTag = '<cite>';
  const captionEndTag = '</cite>';
  const captionBlockStart = caption.indexOf(captionStartTag);
  const captionBlockEnd = caption.indexOf(captionEndTag);

  if (captionBlockStart === -1) {
    return caption;
  }

  if (captionBlockEnd < captionStartTag.length) {
    return caption;
  }

  const beforeText = caption.slice(0, captionBlockStart);
  const afterText = caption.slice(captionBlockEnd + captionEndTag.length);
  const companyText = caption.slice(captionBlockStart + captionStartTag.length, captionBlockEnd);

  return (
    <>
      {beforeText}
      <Company>{companyText}</Company>
      {afterText}
    </>
  );
};

export const Testimonial = ({ quotation, caption, company }) => {
  const { captionText, companyText } = useMemo(
    () => ({
      captionText: renderCaption(caption),
      companyText: renderCompany(company),
    }),
    [caption, company]
  );
  return (
    <Container>
      <Quotation>{quotation}</Quotation>
      <Caption>
        {captionText} {companyText}
      </Caption>
    </Container>
  );
};

Testimonial.propTypes = {
  ...pick(['quotation', 'caption', 'company'], ContentRegistryPropTypes.testimonial),
};
