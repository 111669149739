import { createGlobalStyle } from 'styled-components';
import { colors, fonts, fontSizes, cursorUrls } from './styled';
import { media } from './media';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    cursor: url(${cursorUrls.normal}) 12 12, auto !important;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fonts.neueHaas};
    font-size: ${fontSizes.baseSize.mobile};

    ${media.desktop`
      font-size: ${fontSizes.baseSize.desktop};
    `};

    ${media.desktopFull`
      font-size: ${fontSizes.baseSize.desktopFull};
    `};
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
  }

  a, button, button:before, button:after, a path, a svg {
    cursor: url(${cursorUrls.pointer}) 12 12, auto !important;
  }
`;
