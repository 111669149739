import { pathOr } from 'ramda';

import { IMAGES_SIZES_ARRAY, QUAD_HD } from '../../theme/media';

export const IMAGE_QUALITY = 90;

export const getAssetPath = (assets, image) => pathOr('', [image, 'file', 'url'])(assets);

export const getContentType = (assets, image) => pathOr('', [image, 'file', 'contentType'])(assets);

export const getImageAlt = (assets, image) => pathOr('', [image, 'description'])(assets);

export const getSrcSet = src =>
  IMAGES_SIZES_ARRAY.map((width, index, sorted) =>
    sorted[index + 1]
      ? `${src}?w=${sorted[index + 1]}&q=${IMAGE_QUALITY} ${width}w`
      : `${src}?w=${QUAD_HD}&q=${IMAGE_QUALITY} ${width}w`
  ).toString();

export const preloadImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = url;
  });
