import { Section } from '../section';
import { ImagesComparator } from '../imagesComparator';
import { TestimonialsSection } from '../testimonialsSection';
import { Hero } from '../hero';

export const COMPONENTS_MAP = {
  section: Section,
  imagesComparator: ImagesComparator,
  testimonialsSection: TestimonialsSection,
  hero: Hero,
};
