import PropTypes from 'prop-types';
import { TEXT_ALIGN, HERO_TYPES } from './contentRegistry.constants';
import { colors } from '../../theme/styled';

const sys = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
});

const asset = PropTypes.shape({
  sys: sys.isRequired,
  title: PropTypes.string.isRequired,
  file: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
});

const page = PropTypes.shape({
  sys: sys.isRequired,
  name: PropTypes.string.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  menuLabel: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string),
});

const section = {
  sys: sys.isRequired,
  name: PropTypes.string.isRequired,
  elementId: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  text: PropTypes.string,
  textAlign: PropTypes.oneOf(Object.values(TEXT_ALIGN)).isRequired,
  textColor: PropTypes.oneOf(Object.keys(colors)),
  textBackgroundColor: PropTypes.oneOf(Object.keys(colors)),
};

const imagesComparator = {
  sys: sys.isRequired,
  elementId: PropTypes.string,
  firstImage: PropTypes.string.isRequired,
  secondImage: PropTypes.string.isRequired,
};

const hero = {
  sys: sys.isRequired,
  type: PropTypes.oneOf(Object.values(HERO_TYPES)).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
};

const testimonialsSections = {
  sys: sys.isRequired,
  elementId: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  testimonials: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const testimonial = {
  sys: sys.isRequired,
  quotation: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  company: PropTypes.string,
};

export const ContentRegistryPropTypes = {
  asset,
  page,
  section,
  imagesComparator,
  hero,
  testimonialsSections,
  testimonial,
};
