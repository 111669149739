import React from 'react';
import { pick } from 'ramda';

import { ContentRegistryPropTypes } from '../../../modules/contentRegistry';
import { HERO_TYPES } from '../../../modules/contentRegistry/contentRegistry.constants';
import { Container, Video, TextWrapper, Title, Image } from './hero.styles';

export const Hero = ({ title, type, image, video }) => {
  if (type === HERO_TYPES.VIDEO) {
    return (
      <Container>
        <Video video={video} />
      </Container>
    );
  }

  return (
    <Container>
      <TextWrapper>
        <Title>{title}</Title>
      </TextWrapper>
      <Image image={image} />
    </Container>
  );
};

Hero.propTypes = {
  ...pick(['title', 'text', 'type', 'image', 'video'], ContentRegistryPropTypes.hero),
};
