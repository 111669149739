import styled, { css } from 'styled-components';
import { fonts, fontSizes, fontWeights } from '../../../../theme/styled';
import { media } from '../../../../theme/media';
import { paragraphsDistanceStyle, paragraphsWidthStyle } from '../../../../theme/typography';

export const Container = styled.figure`
  margin: 0;
  font-size: ${fontSizes.paragraph.mobile};
  ${paragraphsWidthStyle}

  ${media.desktopWide`
    font-size: ${fontSizes.paragraph.desktopWide};
  `};
`;

export const Caption = styled.figcaption`
  margin: 0;
  text-align: left;
`;

const quoteStyle = css`
  quotes: '„' '”';

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
`;

export const Quotation = styled.blockquote`
  margin: 0;
  font-family: ${fonts.neueHaas};
  font-weight: ${fontWeights.normal};
  text-align: left;
  line-height: 1.8;
  ${quoteStyle};

  & + ${Caption} {
    ${paragraphsDistanceStyle};
  }
`;

export const Company = styled.cite`
  display: inline-block;
  font-style: normal;
`;
