import React from 'react';
import PropTypes from 'prop-types';

import { whenElementExist, scrollToRef } from '../../../utils/scroll';
import { useMobileDetect } from '../../../utils/hooks';
import { MenuAnchorLink, MenuLink } from './menuItem.styles';

export const MenuItem = props => {
  const isMobile = useMobileDetect();
  if (props.elementId) {
    const handleClick = () => {
      if (isMobile) {
        props.onClick();
      }

      setTimeout(
        () => {
          whenElementExist(props.elementId, scrollToRef);
        },
        isMobile ? 700 : 0
      );
    };
    return <MenuAnchorLink onClick={handleClick}>{props.menuLabel}</MenuAnchorLink>;
  }

  return (
    <MenuLink activeClassName="active" to={props.url}>
      {props.menuLabel}
    </MenuLink>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  elementId: PropTypes.string,
  menuLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
