import React from 'react';
import { useSelector } from 'react-redux';
import { pick } from 'ramda';
import Slider from 'react-slick';

import { ContentRegistryPropTypes, selectTestimonials } from '../../../modules/contentRegistry';
import { Testimonial } from './testimonial';
import { SliderWrapper, Background, Image, Container } from './testimonialsSection.styles';
import { SLIDER_SETTINGS } from './testimonialsSection.constants';

const renderTestimonials = (testimonials, allTestimonials) =>
  testimonials.map(testimonial => (
    <div key={testimonial}>
      <Testimonial {...allTestimonials[testimonial]} />
    </div>
  ));

export const TestimonialsSection = ({ elementId, testimonials, backgroundImage }) => {
  const allTestimonials = useSelector(selectTestimonials);

  return (
    <Container id={elementId}>
      <SliderWrapper>
        <Slider {...SLIDER_SETTINGS}>{renderTestimonials(testimonials, allTestimonials)}</Slider>
      </SliderWrapper>
      <Background>
        <Image image={backgroundImage} />
      </Background>
    </Container>
  );
};

TestimonialsSection.propTypes = {
  ...pick(['sectionId', 'testimonials', 'backgroundImage'], ContentRegistryPropTypes.testimonialsSections),
};
