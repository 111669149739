import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { H1, getHeightStyles, buttonLinkStyles, paragraphsDistanceStyle } from '../../theme/typography';
import { colors, metrics } from '../../theme/styled';
import { media } from '../../theme/media';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  ${getHeightStyles({ dynamic: false })};
  margin-top: ${metrics.mainMenuHeight.mobile}px;

  ${media.desktop`
    margin-top: ${metrics.mainMenuHeight.desktop}px;
  `};

  ${media.desktopWide`
    margin-top: ${metrics.mainMenuHeight.desktopWide}px;
  `};

  ${media.desktopFull`
    margin-top: ${metrics.mainMenuHeight.desktopFull}px;
  `};
`;

export const Title = styled(H1)`
  color: ${colors.primary};
`;

export const Link = styled(RouterLink)`
  ${buttonLinkStyles};
  ${paragraphsDistanceStyle};
  font-size: inherit;
  color: inherit;
`;
