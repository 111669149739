import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { App } from './app.component';
import { Home } from './home';
import { NotFound } from './notFound';
import { ROUTES } from './routes';

export default () => {
  return (
    <App>
      <Switch>
        <Route path={ROUTES.notFound} component={NotFound} />
        <Route path={ROUTES.home} component={Home} />
      </Switch>
    </App>
  );
};
