import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Container, Content, Text, TextButton, ButtonWrapper, CrossButton } from './cookiesBar.styles';
import { selectSettings, selectCookies, ContentRegistryActions } from '../../../modules/contentRegistry';

export const CookiesBar = () => {
  const settings = useSelector(selectSettings);
  const cookies = useSelector(selectCookies);
  const dispatch = useDispatch();

  const handleAcceptCookies = () => dispatch(ContentRegistryActions.setCookies(true));

  const handleRejectCookies = () => dispatch(ContentRegistryActions.setCookies(false));

  if (!settings || cookies) {
    return null;
  }

  const { cookiesContent, cookiesButtonLabel } = settings;

  return (
    <Container>
      <Content>
        <Text>{cookiesContent}</Text>
        <ButtonWrapper>
          <TextButton onClick={handleAcceptCookies}>{cookiesButtonLabel}</TextButton>
          <CrossButton onClick={handleRejectCookies} />
        </ButtonWrapper>
      </Content>
    </Container>
  );
};
