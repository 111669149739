import circleUrl from '../images/icons/circle.svg';
import circleOutlineUrl from '../images/icons/circle-outline.svg';

export const colors = {
  transparent: 'transparent',
  white: '#ffffff',
  primary: '#000000',
  grey: '#8b8d90',
  accent: '#0000e1',
  secondary: '#fbf9f8',
  shadow: 'rgba(0, 0, 0, 0.1)',
  white40: 'rgba(255, 255, 225, 0.4)',
  whiteTransparent: 'rgba(255, 255, 255, 0)',
};

export const fonts = {
  neueHaas: '"neue-haas-grotesk-text", Arial, sans-serif',
};

export const fontSizes = {
  baseSize: {
    mobile: '12px',
    desktop: '15px',
    desktopFull: '16px',
  },
  h1: {
    mobile: '1.333em',
    tablet: '1.777em',
    desktop: '2.369em',
  },
  h2: {
    mobile: '1em',
    tablet: '1.333em',
    desktop: '1.777em',
  },
  h3: {
    mobile: '1em',
    desktopWide: '1.333em',
  },
  h4: {
    mobile: '1em',
  },
  h5: {
    mobile: '0.75em',
  },
  h6: {
    mobile: '0.563em',
  },
  paragraph: {
    mobile: '1em',
    desktopWide: '1.333em',
  },
  button: {
    mobile: '1em',
    desktopWide: '1.333em',
  },
  testimonialSignature: {
    mobile: '0.75em',
    desktopWide: '1em',
  },
  menu: {
    desktopFull: '17px',
    tablet: '10px',
    desktop: '15px',
    mobile: '18px',
  },
  unorderedListItem: {
    mobile: '10px',
    desktop: '14px',
    desktopFull: '15px',
  },
};

export const fontWeights = {
  normal: 400,
  bold: 500,
};

export const metrics = {
  cookiesBar: {
    mobile: 110,
    desktop: 75,
    desktopWide: 85,
    desktopFull: 110,
  },
  mainMenuHeight: {
    mobile: 55,
    desktop: 75,
    desktopWide: 85,
    desktopFull: 110,
  },
  buttonBottomMargin: {
    mobile: 30,
    tablet: 70,
    desktop: 70,
    desktopWide: 60,
    desktopFull: 70,
  },
  sectionParagraphWidth: {
    mobile: 230,
    tablet: 260,
    desktop: 380,
    desktopWide: 460,
    desktopFull: 460,
  },
  sectionTopMargin: {
    mobile: 24,
    tablet: 25,
    desktop: 45,
  },
};

export const cursorUrls = {
  normal: circleUrl,
  pointer: circleOutlineUrl,
};
